#navbar {
    background-color: var(--ming) !important;
    font-size: 22px;
}

.navbar-nav a:hover {
    color: var(--jet) !important;
}

#navbar-links {
    gap: 25px;
}

@media screen and (max-width: 480px) {
    #navbar {
        font-size: 15px;
    }

    #navbar-links {
        gap: 3px;
    }

    #navbar-links svg {
        height: 20px;
        width: 20px;
    }
}

@media screen and (max-width: 768px) { 
    #navbar{
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 9999;
    }
}