@import url(https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Coolors Exported Palette - https://coolors.co/353535-3c6e71-ffffff-d9d9d9-284b63 */

:root{
    /* CSS HSL */
    --jet: rgba(53, 53, 53, 1);
    --ming: rgba(60, 110, 113, 1);
    --white: rgba(255, 255, 255, 1);
    --gainsboro: rgba(217, 217, 217, 1);
    --indigo-dye: rgba(40, 75, 99, 1);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}


body {
    color: rgba(60, 110, 113, 1);
    color: var(--ming);
    font-family: 'Space Mono', monospace, sans-serif;
}

#section-title {
    color: rgba(60, 110, 113, 1);
    color: var(--ming);
    font-weight: bolder;
    font-size: 70px;
    text-shadow: 3px 3px rgba(217, 217, 217, 1);
    text-shadow: 3px 3px var(--gainsboro);
}


@media screen and (max-width: 480px) {
    #section-title {
        font-size: 35px;
    }
}
#home {
    color: var(--gainsboro);
    height: 95vh;
    width: 100vw;
    overflow: hidden !important;
}

#home-img {
    opacity: .9;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

#home-img img {
    height: 50vh;
    -webkit-clip-path: polygon(54% 5%, 81% 15%, 100% 45%, 94% 87%, 48% 100%, 16% 94%, 0 62%, 13% 6%);
            clip-path: polygon(54% 5%, 81% 15%, 100% 45%, 94% 87%, 48% 100%, 16% 94%, 0 62%, 13% 6%);
}

.home-text {
    font-weight: bolder;
    transition: 0.3s;
    font-size: 45px;
}

.home-text:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    color: var(--ming);
    cursor: default;
}

#home-button {
    font-size: 18px;
    background-color: var(--jet);
    transition: 0.3s;
}

#home-button:hover {
    font-weight: bolder;
    background-color: var(--ming);
}

@media screen and (max-width: 480px) {
    .home-text {
        font-size: 13px;
    }

    #home-button {
        font-size: 12px;
        width: 260px;
    }

    #home {
        width: 400px;
    }
}
#about-description {
    font-size: 22px;
    padding: 5px;
    margin: 8px;
    color: var(--gainsboro);
}

#about-background {
    background-image: var(--bs-gradient);
    /* padding: 75px 50px 100px 0; */
}

#about-image {
    width: 550px;
    height: 850px;
    border-radius: 15px;
}

@media screen and (max-width: 480px) {
    #about-description {
        font-size: 12px;
    }

    #about-image {
        max-width: auto;
        height: auto;
    }

    #about-section {
        padding: 10px;
    }

    #about-div {
        width: 350px;
        padding-top: 25px;
    }

    #about-background {
        padding: 5px;
    }
}


#contact-links {
    color: var(--gainsboro);
    padding: 5px;
    margin: 8px;
    font-size: 18px;
}

#contact-links a {
    text-decoration: none;
    color: inherit;
    transition: 0.3s;
}

#contact-links a:hover {
    /* transform: scale(1.1); */
    color: #ffb703;
    font-weight: bolder;
}

#contact-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

#contact-background {
    background-image: var(--bs-gradient);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

@media screen and (max-width: 480px) {
    #contact-links span {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        font-size: 15px;
    }
}
#project-card {
    width: 30rem;
    transition: 0.3s;
    height: 100%;
}

#project-card img {
    height: 318px;
}

.card-text {
    font-size: 16px;
}

#project-card:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 8px 8px var(--ming);
}

#project-card:hover h4 {
    color: #ffb703;
}

.card-body {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 15px;
}

#project-tech {
    font-weight: bolder;
}

#project-links {
    display: -webkit-flex;
    display: flex;
    grid-gap: 50px;
    gap: 50px;
}

#project-links a {
    text-decoration: none;
    color: inherit;
    transition: 0.5s;
}

#project-links a:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    color: #ffb703;
    font-weight: bolder;
}

@media screen and (max-width: 480px) {
    #project-card {
        font-size: 12px;
        width: 21rem;
    }

    .card-text {
        font-size: 12px;
    }
}
#techstack-img {
  height: 90px;
  width: auto;
}

#tech-card {
  transition: 0.3s;
}

#tech-card:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

#tech-card:hover h3 {
  color: #ffb703;
}

#tech-stack img {
  padding: 10px;
}

#tech-stack-title {
  color: var(--gainsboro);
}

#tech-stack {
  background-image: var(--bs-gradient);
}

@media screen and (max-width: 480px) {
  #tech-stack img {
    width: 100px;
    height: auto;
  }

  #tech-stack {
    width: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }

  #tech-stack-title {
    font-size: 15px;
  }

  #tech-card {
    width: 150px;
  }
}

#navbar {
    background-color: var(--ming) !important;
    font-size: 22px;
}

.navbar-nav a:hover {
    color: var(--jet) !important;
}

#navbar-links {
    grid-gap: 25px;
    gap: 25px;
}

@media screen and (max-width: 480px) {
    #navbar {
        font-size: 15px;
    }

    #navbar-links {
        grid-gap: 3px;
        gap: 3px;
    }

    #navbar-links svg {
        height: 20px;
        width: 20px;
    }
}

@media screen and (max-width: 768px) { 
    #navbar{
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 9999;
    }
}
