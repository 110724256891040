/* Coolors Exported Palette - https://coolors.co/353535-3c6e71-ffffff-d9d9d9-284b63 */

:root{
    /* CSS HSL */
    --jet: rgba(53, 53, 53, 1);
    --ming: rgba(60, 110, 113, 1);
    --white: rgba(255, 255, 255, 1);
    --gainsboro: rgba(217, 217, 217, 1);
    --indigo-dye: rgba(40, 75, 99, 1);
}

@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}


body {
    color: var(--ming);
    font-family: 'Space Mono', monospace, sans-serif;
}

#section-title {
    color: var(--ming);
    font-weight: bolder;
    font-size: 70px;
    text-shadow: 3px 3px var(--gainsboro);
}


@media screen and (max-width: 480px) {
    #section-title {
        font-size: 35px;
    }
}