#contact-links {
    color: var(--gainsboro);
    padding: 5px;
    margin: 8px;
    font-size: 18px;
}

#contact-links a {
    text-decoration: none;
    color: inherit;
    transition: 0.3s;
}

#contact-links a:hover {
    /* transform: scale(1.1); */
    color: #ffb703;
    font-weight: bolder;
}

#contact-section {
    display: flex;
    justify-content: center;
}

#contact-background {
    background-image: var(--bs-gradient);
    width: fit-content;
}

@media screen and (max-width: 480px) {
    #contact-links span {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 15px;
    }
}