#techstack-img {
  height: 90px;
  width: auto;
}

#tech-card {
  transition: 0.3s;
}

#tech-card:hover {
  transform: scale(1.2);
}

#tech-card:hover h3 {
  color: #ffb703;
}

#tech-stack img {
  padding: 10px;
}

#tech-stack-title {
  color: var(--gainsboro);
}

#tech-stack {
  background-image: var(--bs-gradient);
}

@media screen and (max-width: 480px) {
  #tech-stack img {
    width: 100px;
    height: auto;
  }

  #tech-stack {
    width: auto;
    display: flex;
    justify-content: center;
  }

  #tech-stack-title {
    font-size: 15px;
  }

  #tech-card {
    width: 150px;
  }
}
