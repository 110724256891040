#about-description {
    font-size: 22px;
    padding: 5px;
    margin: 8px;
    color: var(--gainsboro);
}

#about-background {
    background-image: var(--bs-gradient);
    /* padding: 75px 50px 100px 0; */
}

#about-image {
    width: 550px;
    height: 850px;
    border-radius: 15px;
}

@media screen and (max-width: 480px) {
    #about-description {
        font-size: 12px;
    }

    #about-image {
        max-width: auto;
        height: auto;
    }

    #about-section {
        padding: 10px;
    }

    #about-div {
        width: 350px;
        padding-top: 25px;
    }

    #about-background {
        padding: 5px;
    }
}

