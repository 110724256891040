#project-card {
    width: 30rem;
    transition: 0.3s;
    height: 100%;
}

#project-card img {
    height: 318px;
}

.card-text {
    font-size: 16px;
}

#project-card:hover {
    transform: scale(1.1);
    box-shadow: 8px 8px var(--ming);
}

#project-card:hover h4 {
    color: #ffb703;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

#project-tech {
    font-weight: bolder;
}

#project-links {
    display: flex;
    gap: 50px;
}

#project-links a {
    text-decoration: none;
    color: inherit;
    transition: 0.5s;
}

#project-links a:hover {
    transform: scale(1.1);
    color: #ffb703;
    font-weight: bolder;
}

@media screen and (max-width: 480px) {
    #project-card {
        font-size: 12px;
        width: 21rem;
    }

    .card-text {
        font-size: 12px;
    }
}